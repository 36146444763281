import React from 'react'

function Info() {
  return (
    <div>
      info
    </div>
  )
}

export default Info
