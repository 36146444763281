import React, { useEffect, useState } from 'react'
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useParams } from 'react-router-dom';

function Meet() {
 
  const [meeting, setMeetings] = useState([]);
  const [users, setUsers] = useState([]);



  const getmeetbyemail = async () => {


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("email", email);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudulebyemail", requestOptions)
      .then((response) => response.json())
      .then(async (result) => {

        await setMeetings(result)




      })



      .catch((error) => console.error(error));



    // await setUsers(userData);

  }



  useEffect(() => {
    getmeetbyemail()
  }, [])



  const getUser = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfo", requestOptions);
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };




  useEffect(() => {
    const fetchUserDetails = async () => {
      if (meeting && meeting.id) { // Check if meeting data exists and has an id
        const userData = await getUser(meeting.id);

        setUsers(userData); // Set user data as an array with one item
      }

    };

    fetchUserDetails();

  }, [meeting]);



  const [oppi, setoppi] = useState([])


  const getmeet = async () => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("id", users._id);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getbookingmeetbyuserid", requestOptions)
      .then((response) => response.json())
      .then((result) => {
       
        const meeting = result.find(item => item._id === meetingID);
       setoppi(meeting)


      })
      .catch((error) => console.error(error))

  }

  useEffect(() => {

    getmeet();

  }, [users]);





















  const { email, meetingID } = useParams();

  function generateRandomID() {
    return Math.random().toString(36).substr(2, 9);
  }


  const myMeeting = async (element) => {


    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      parseInt("1426619796"),
      "224cefed6979db88285a5c524756adf4",
      meetingID,//meeting id
      generateRandomID(), //for test
      oppi.details[0].Name,//for test
      // user?.uid ? user.uid : generateMeetingID(),
      // user?.displayName ? user.displayName : generateMeetingID()
    );
    const zp = ZegoUIKitPrebuilt.create(kitToken);

    zp?.joinRoom({
      container: element,
      showPreJoinView: true,
      showScreenSharingButton: true,
      showRemoveUserButton: true,
      showRoomTimer: true,

      sharedLinks: [
        {
          name: "meeting.title",
          url: "https://meet.yourgutmap.co.uk/" + email + meetingID,
        },
      ],
      scenario: {
        mode: "VideoCall",
        config: {
          role: "Participant",
        }
      },
    });



  };


  return (
    <div>
     {oppi.length === 0? (<>loading..</>):(<>
      <div
        className="myCallContainer"
        ref={myMeeting}
        style={{ width: "100%", height: "100vh" }}
      ></div>
      </>)}
    
    </div>
  )
}

export default Meet
