import React, { useEffect, useState } from 'react';
import '../css/index.css';
import { Image, img } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


function Seeteams() {
  const { catogory } = useParams();
  let heading

  if (catogory === "nutrition") {
    heading = "Our Nutrition Team"
  } else if (catogory === "sales") {
    heading = "Our Account Management Team"
  } else if (catogory === "it") {
    heading = "Our IT Team"
  }

  const navigate = useNavigate();
  const [meetings, setMeetings] = useState([]);
  const [users, setUsers] = useState([]);

  const getMeet = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudule", requestOptions)
      .then(response => response.json())
      .then(result => {
        const filteredResults = result.filter(value => value.Category === catogory);

        // Set the filtered meetings to state or use them as needed
        setMeetings(filteredResults);
      }

      )
      .catch(error => console.error(error));
  };

  const getUser = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfo", requestOptions);
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  useEffect(() => {
    getMeet();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userPromises = meetings.map(meeting => getUser(meeting.id));
      const userData = await Promise.all(userPromises);
      setUsers(userData.filter(user => user !== null));
    };

    if (meetings.length > 0) {
      fetchUserDetails();
    }
  }, [meetings]);




















  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Initialize state with current year and month
  const currentDate = new Date();
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());


  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };


  const generateDates = (month, year) => {
    const numDays = daysInMonth(month, year);
    const firstDayOfMonth = new Date(year, month, 1).getDay(); // Get the weekday of the first day of the month (0 - Sunday, 1 - Monday, ...)
    const dates = Array.from({ length: firstDayOfMonth }, (_, i) => ''); // Create empty cells for days before the first day of the month
    for (let i = 1; i <= numDays; i++) {
      dates.push(i);
    }
    return dates;
  };



  const chunkArray = (arr, size) => {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  };













  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      height: "50px"
    },
    button: {
      background: "none",
      border: "none",
      cursor: "pointer",
      fontSize: "20px",
      color: "#6A1B9A", // Purple color similar to the image
    },
    label: {
      fontSize: "16px",
      color: "#6A1B9A", // Purple color similar to the image
      textAlign: "center",
    },
  };




  const [date, setDate] = useState(new Date(selectedYear, selectedMonth));

  const handlePrev = () => {
    const newDate = new Date(selectedYear, selectedMonth - 1); // Calculate the new date
    setDate(newDate); // Update the date state
    setSelectedYear(newDate.getFullYear()); // Update the year state
    setSelectedMonth(newDate.getMonth()); // Update the month state
  };

  const handleNext = () => {
    const newDate = new Date(selectedYear, selectedMonth + 1); // Calculate the new date
    setDate(newDate); // Update the date state
    setSelectedYear(newDate.getFullYear()); // Update the year state
    setSelectedMonth(newDate.getMonth()); // Update the month state
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };




  const [alldays, setalldays] = useState([])


  useEffect(() => {

    const daysArray = [];


    meetings.map((meeting) => {

      const startDate = new Date(meeting.rangedate[0]);
      const endDate = new Date(meeting.rangedate[1]);


      let currentDate = startDate;
      while (currentDate <= endDate) {
        daysArray.push(currentDate.toISOString().split('T')[0]); // Format date as 'YYYY-MM-DD'
        currentDate.setDate(currentDate.getDate() + 1);
      }



    })

    setalldays(daysArray);

  }, [meetings]);



  return (
    <div className='mainindex2'>


      <h2>  <h1 style={{ color: "#6E4E9F" }}>{heading}</h1></h2>

      <div className='seeteammeain' >

        {(meetings.length > 0 && users.length > 0) ? (<>
          {meetings.map((value, index) => {

            const meetinguser = users.find(value2 => value2._id === value.id)

            return (<>

              {index === 6556356336 && <>
                <div className='userbox2' >
                  <div className='insidebox'>
                    <h2>Availibility</h2>

                    <p className='userboxabout'>Contrary to popular belief, Lorem Ipsum is not simply random Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>

                  </div>
                  <div className="calendar">

                    <h2>Select A Date</h2>



                    <div style={styles.container}>
                      <button style={styles.button} onClick={handlePrev}>
                        <FaArrowLeft />
                      </button>
                      <div style={styles.label}>{formatDate(date)}</div>
                      <button style={styles.button} onClick={handleNext}>
                        <FaArrowRight />
                      </button>
                    </div>

                    <table cellSpacing={"5px"}>
                      <thead>
                        <tr>
                          {daysOfWeek.map(day => (
                            <th key={day}>{day}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {chunkArray(generateDates(selectedMonth, selectedYear), 7).map((week, index) => (
                          <tr key={index}>
                            {week.map((date, index) => {
                              let test = ""

                              let color = ""
                              let link

                              const formattedMonth = String(parseInt(selectedMonth + 1)).padStart(2, '0'); // Zero-pad month
                              const formattedDate = String(parseInt(date)).padStart(2, '0'); // Zero-pad day
                              const thisdate = `${selectedYear}-${formattedMonth}-${formattedDate}`;

                              // Check if `thisdate` exists in `alldays`
                              if (alldays.includes(thisdate)) {


                                const dayOfWeek = new Date(thisdate).toLocaleString('en-US', { weekday: 'long' }).toLowerCase(); // e.g., "monday", "tuesday"

                                // if  thisdate is in meetings[0].rangedate

                                meetings.map((meeting) => {
                                  const startingdate = meeting.rangedate[0]  // YYYY-MM-DD
                                  const endingdate = meeting.rangedate[1] // YYYY-MM-DD


                                 


                                  if (new Date(thisdate) >= new Date(startingdate) && new Date(thisdate) <= new Date(endingdate)) {


                                    // console.log(`Date ${thisdate} is within the range ${startingdate} to ${endingdate}.`);
                                    if (
                                      (dayOfWeek === 'monday' && meeting.Monday) ||
                                      (dayOfWeek === 'tuesday' && meeting.Tuesday) ||
                                      (dayOfWeek === 'wednesday' && meeting.Wednesday) ||
                                      (dayOfWeek === 'thursday' && meeting.Thursday) ||
                                      (dayOfWeek === 'friday' && meeting.Friday) ||
                                      (dayOfWeek === 'saturday' && meeting.Saturday) ||
                                      (dayOfWeek === 'sunday' && meeting.Sunday)
                                    ) {


                                      const dateExistssss = meeting.Dateexception.some(day => day === thisdate);

                                      if (!dateExistssss) {




                                        test = "shed";

                                        const userrrrrr = users.find(value => value._id === meeting.id)
                                        link = `/${userrrrrr.email}?date=`
                                      }
                                    }

                                  } else {


                                 

                                  }
                                })



                                // Check if `thisdate` matches a weekday in `meeting` with `true` value

                              }

                              // if (clickday === thisdate) {
                              //     color = "#20014e"
                              // }


                              return (
                                <td className={test} key={index}

                                  onClick={() => navigate(`${link}` + thisdate)}

                                // onClick={() => {
                                //     if (test === "shed") {
                                //         settimee(true);
                                //         setclickday(thisdate);

                                //         const dayOfWeek = new Date(thisdate).toLocaleString('en-UK', { weekday: 'long' }).toLowerCase(); // e.g., "monday", "tuesday"
                                //         let timeSlots = [];
                                //         let duration = parseInt(meeting.duration); // Duration in minutes

                                //         const createTimeSlots = (startTime, endTime, interval) => {
                                //             let start = new Date(`${thisdate} ${startTime}`);
                                //             let end = new Date(`${thisdate} ${endTime}`);

                                //             if (isNaN(start) || isNaN(end)) {
                                //                 console.error('Invalid date parsing:', startTime, endTime);
                                //                 return;
                                //             }

                                //             let currentTime = start;
                                //             while (currentTime < end) {
                                //                 timeSlots.push(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
                                //                 currentTime = new Date(currentTime.getTime() + interval * 60000); // Add interval in milliseconds
                                //             }
                                //         };

                                //         if (dayOfWeek === 'monday' && meeting.Mondaytime.length > 1) {
                                //             createTimeSlots(meeting.Mondaytime[0], meeting.Mondaytime[1], duration);
                                //             setalltime(timeSlots);
                                //         } else if (dayOfWeek === 'tuesday' && meeting.Tuesdaytime.length > 1) {
                                //             createTimeSlots(meeting.Tuesdaytime[0], meeting.Tuesdaytime[1], duration);
                                //             setalltime(timeSlots);
                                //         } else if (dayOfWeek === 'wednesday' && meeting.Wednesdaytime.length > 1) {
                                //             createTimeSlots(meeting.Wednesdaytime[0], meeting.Wednesdaytime[1], duration);
                                //             setalltime(timeSlots);
                                //         } else if (dayOfWeek === 'thursday' && meeting.Thursdaytime.length > 1) {
                                //             createTimeSlots(meeting.Thursdaytime[0], meeting.Thursdaytime[1], duration);
                                //             setalltime(timeSlots);
                                //         } else if (dayOfWeek === 'friday' && meeting.Fridaytime.length > 1) {
                                //             createTimeSlots(meeting.Fridaytime[0], meeting.Fridaytime[1], duration);
                                //             setalltime(timeSlots);
                                //         } else if (dayOfWeek === 'saturday' && meeting.Saturdaytime.length > 1) {
                                //             createTimeSlots(meeting.Saturdaytime[0], meeting.Saturdaytime[1], duration);
                                //             setalltime(timeSlots);
                                //         } else if (dayOfWeek === 'sunday' && meeting.Sundaytime.length > 1) {
                                //             createTimeSlots(meeting.Sundaytime[0], meeting.Sundaytime[1], duration);
                                //             setalltime(timeSlots);
                                //         }
                                //     }
                                // }}


                                >
                                  {/* {test} */}
                                  {date}

                                </td>
                              )
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </div>

              </>}
              <div className='userbox' >

                {catogory === "nutrition" && <>
                  <p className='rebin'> {value.rebin.map((value,index) => (<>{index !== 0 && <>, </>}{value}  </>))}</p>
                  <p className='rebin2'> {meetinguser.name} can help with</p>
                 
                </>}
                <div style={{ height: '' }}></div>
                <Image src={meetinguser.profilepic ? (meetinguser.profilepic) : ("")} alt='' width={"230px"} height={"230px"} style={{ borderRadius: "50%" }} />
                <div className='insidebox'>
                  <h2>{meetinguser.name}</h2>

                  <p className='ppppp'>{value.discribtion}</p>
                  <div>   <button onClick={() => { navigate("/" + meetinguser.email) }} className='formalbtn'>BOOK AN APPOINTMENT</button></div>
                </div>
              </div>
            </>)
          })}


        </>) : (<>loading...</>)}

      </div>

    </div>
  )
}

export default Seeteams
