import React, { useEffect, useState } from 'react';
import '../css/index.css';
import { img } from 'antd';
import { useNavigate } from 'react-router-dom';

function Index() {
    const img = "https://pics.craiyon.com/2023-11-26/oMNPpACzTtO5OVERUZwh3Q.webp";
    const navigate = useNavigate();
    const [meetings, setMeetings] = useState([]);
    const [users, setUsers] = useState([]);

    const getMeet = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudule", requestOptions)
            .then(response => response.json())
            .then(result => setMeetings(result))
            .catch(error => console.error(error));
    };

    const getUser = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("_id", id);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        try {
            const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfo", requestOptions);
            return await response.json();
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    };

    useEffect(() => {
        getMeet();
    }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const userPromises = meetings.map(meeting => getUser(meeting.id));
            const userData = await Promise.all(userPromises);
            setUsers(userData.filter(user => user !== null));
        };

        if (meetings.length > 0) {
            fetchUserDetails();
        }
    }, [meetings]);

    return (
        <div className='mainindex'>
            <div className='uperuser'>
                <div className='userbox22' >
                    {/* <img src="/prac.png" alt='' width={"230px"} height={"230px"} style={{ borderRadius: "50%" }} /> */}
                    <div className='insidebox'>
                        <h1><h2>Nutrition Team</h2></h1>

                        <h2 ><p >Book a results interpretation session with one of our qualified gut health experts.</p></h2>
                        {/* <div>   <button onClick={() => { navigate("/teams/nutrition") }} className='formalbtn'>View Details</button></div> */}
                    </div>

                </div>
                <div>   <button onClick={() => { navigate("/teams/nutrition") }} className='formalbtn'>Book a Meeting</button></div>
            </div>


            <div className='uperuser'>
                <div className='userbox22' >
                    {/* <img src="/prac.png" alt='' width={"230px"} height={"230px"} style={{ borderRadius: "50%" }} /> */}
                    <div className='insidebox'>
                          <h1><h2>Account Management Team</h2></h1>

                          <h2 ><p > Learn more about YourGutMap, or book a test training session.</p></h2>
                        {/* <div>   <button onClick={() => { navigate("/teams/sales") }} className='formalbtn'>View Details</button></div> */}
                    </div>

                </div>
                <div>   <button onClick={() => { navigate("/teams/sales") }} className='formalbtn'>Book a Meeting</button></div>
            </div>


            <div className='uperuser'>
                <div className='userbox22' >
                    {/* <img src="/prac.png" alt='' width={"230px"} height={"230px"} style={{ borderRadius: "50%" }} /> */}
                    <div className='insidebox'>
                         <h1><h2>IT Team</h2></h1>

                         <h2 ><p >If you need to reset your password, or need technical help, you can reach our IT team here.</p></h2>
                        {/* <div>   <button onClick={() => { window.location.href = ("https://help.yourgutmap.co.uk/ticket") }} className='formalbtn'>View Details</button></div> */}
                    </div>

                </div>
                <div>   <button onClick={() => { window.location.href = ("https://help.yourgutmap.co.uk/ticket") }} className='formalbtn'>Book a Meeting</button></div>
            </div>

            {/* <div className='userbox22' >
                <img src="/nut.png" alt='' width={"230px"} height={"230px"} style={{ borderRadius: "50%" }} />
                 <div className='insidebox'>
                    <h2>IT Team</h2>

                    <p className='userboxabout'>If you need to reset your password, or need technical help, you can reach our IT team here.</p>
                    <div>   <button onClick={() => { window.location.href = ("https://help.yourgutmap.co.uk/ticket") }} className='formalbtn'>View Details</button></div>
                </div>
            </div>

            <div className='userbox22' >
                <img src="/it.png" alt='' width={"230px"} height={"230px"} style={{ borderRadius: "50%" }} />
                <div className='insidebox'>
                    <h2>IT Team</h2>

                    <p className='userboxabout'>If you need to reset your password, or need technical help, you can reach our IT team here.</p>
                    <div>   <button onClick={() => { window.location.href = ("https://help.yourgutmap.co.uk/ticket") }} className='formalbtn'>View Details</button></div>
                </div>
            </div> */}


        </div>
    );
}

export default Index;
